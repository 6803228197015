'use client'

import { Card } from '@/components/products/card/product-card'
import { ResolvedImage } from '@/components/utility/image'
import KeepOutOfViewIfNotNeeded from '@/components/utility/keep-out-of-view'
import {
  PaginationList,
  PaginationRepeater,
} from '@/components/utility/pagination/pagination-list'
import collections from '@/data/collections.json'
import Link from 'next/link'
import { useMemo } from 'react'

interface iCollectionsPaginationListProps {
  title: string
  seeAllLink: string
  row: number
}

const CollectionsPaginationListInner = ({ row }: { row: number }) => {
  const data = useMemo(() => {
    return collections
      .filter((collection) => collection.row === row)
      .sort((left, right) => left.position - right.position)
  }, [row])

  return (
    <PaginationRepeater className='mt-[-21px]'>
      {data.map(({ imageUrl, lasooUrl, collectionName }, index) => {
        return (
          <Card
            key={index}
            data-testid='collections-pagination-list-card'
            fixedWidth
            breakPointForSizeTransition='lg'
            vertical={false}
            className='relative mb-2 mt-3 !aspect-[1145/767] !h-auto !max-w-[393px] !border-none !p-0'
          >
            <Link href={lasooUrl}>
              <ResolvedImage
                fill
                src={imageUrl}
                priority
                alt={collectionName}
                shouldUseRoot
                className='z-[1]'
              />
              <div className='absolute inset-x-3 inset-y-[15px] z-0 animate-pulse rounded-2xl bg-gray-200' />
            </Link>
          </Card>
        )
      })}
    </PaginationRepeater>
  )
}

export default function CollectionsPaginationList({
  seeAllLink,
  title,
  row,
}: iCollectionsPaginationListProps) {
  return (
    <KeepOutOfViewIfNotNeeded
      fallbackComponent={null}
      component={
        <PaginationList
          containerClass='mt-0 mb-0'
          isSkeleton={false}
          title={title}
          seeAllLink={seeAllLink}
        >
          <CollectionsPaginationListInner row={row} />
        </PaginationList>
      }
    />
  )
}
