import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.24.9_supports-color@9.4.0__@opentelemetry+api@1.9.0_@playwright+tes_c3sxq47vzk56jnxwdkpnsxvc4i/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.24.9_supports-color@9.4.0__@opentelemetry+api@1.9.0_@playwright+tes_c3sxq47vzk56jnxwdkpnsxvc4i/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.6_@babel+core@7.24.9_supports-color@9.4.0__@opentelemetry+api@1.9.0_@playwright+tes_c3sxq47vzk56jnxwdkpnsxvc4i/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Auto.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Baby.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Beauty.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Commercial.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Electronics.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Fashion.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Furniture.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Groceries.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Hardware.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Healthcare.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Hobbies.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Home n Garden.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Jewellery.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Liquor.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Lugage.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Movies Music Books.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Offers & Savings.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Office.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Pet.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Photography.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Religious.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Software.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Sports.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Toys & Games.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/public/category/banners/Travel.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/components/advertisements/advertisement-pagination-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BaseContainer","BrandLogo"] */ "/vercel/path0/web/src/components/catalogues/card/catalogue-item-components.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoriesMenuComponent"] */ "/vercel/path0/web/src/components/categories/category-menu/categories-menu-components.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomePageText"] */ "/vercel/path0/web/src/components/content-feed/home-page-text.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/components/elements/logos/circle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BreadCrumbNavBar"] */ "/vercel/path0/web/src/components/navigation/breadcrumb-navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImageColumn","Description","Footer"] */ "/vercel/path0/web/src/components/products/card/product-card-components.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/components/products/card/product-card-timer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/web/src/components/products/collections/collections-pagination-list.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/components/utility/image.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/components/utility/keep-out-of-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RepeaterContainer","PaginationListProvider","LeftButton","RightButton","SeeAllButton","PaginatorContainer"] */ "/vercel/path0/web/src/components/utility/pagination/pagination-list-components.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebPlayerProvider"] */ "/vercel/path0/web/src/components/web-player/web-player-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WishListLoveItButton"] */ "/vercel/path0/web/src/components/wishlist/wishlist-button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/icons/icon.tsx");
